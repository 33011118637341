@import "../../theme.scss";

.header {
  position: relative;
  width: 100%;
  background-color: $white;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .socials {
    position: absolute;
    right: 40px;
    top: 10px;
    display: flex;
    gap: 10px;
  }

  .logo {
    max-width: 90%;
    height: auto;
    margin-bottom: 10px;
    padding: 10px;
  }

  .NavMenu {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 780px) {
    .socials {
      display: none;
    }
  }
}
