@import "../../theme.scss";

.nav-menu {
  background-color: $dark-green;
  width: 100%;
  padding: 20px;
  text-align: center;

  & .menu-link {
    margin-right: 30px;
    font-family: "Helvetica Neue", sans-serif;
    color: $light-gray;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: none;
    min-width: 100px;

    &:hover {
      text-decoration: underline $white;
      text-underline-offset: 5px;
    }
    &.is-active {
      text-decoration: underline $white;
      text-underline-offset: 5px;
    }
    &:first-of-type {
      margin-left: 30px;
    }
    &:last-of-type {
      margin: 0;
    }
  }
}
