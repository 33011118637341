@import "../../theme.scss";

.footer {
  width: 100%;
  background-color: $black;
  color: $light-gray;
  padding: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
