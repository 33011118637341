// Colors.scss

$black: #000000;
$white: #ffffff;
$light-gray: #f0f0f0;
$dark-green: #183a1d;

body {
  background-color: $light-gray;
  color: $black;
  font-family: Arial, sans-serif;
}

h2 {
  color: $dark-green;
}

input {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #e4e4e7;
  line-height: 20px;
  width: 100%;
  margin: 0 0 32px;
  background-color: #fcfcfc;
  border-radius: 4px;
}

textarea {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #e4e4e7;
  line-height: 20px;
  width: 100%;
  margin: 0 0 32px;
  background-color: #fcfcfc;
  border-radius: 4px;
  min-height: 200px;
}

button.btn {
  padding: 10px;
  background: $dark-green;
  border-radius: 5px;
  color: $white;
  min-width: 40px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
}

.link {
  cursor: pointer;
}
