.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  .main-app-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center; // Center content horizontally
    width: 100%; // Ensure it takes up full width without overflow
  }

  footer {
    width: 100%; // Ensure footer takes up full width
  }
}
