body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-0 {
  padding: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.px-0 {
  padding-right: 0 !important;
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.p-1 {
  padding: 4px !important;
}
.pt-1 {
  padding-top: 4px !important;
}
.pr-1 {
  padding-right: 4px !important;
}
.pb-1 {
  padding-bottom: 4px !important;
}
.pl-1,
.px-1 {
  padding-left: 4px !important;
}
.px-1 {
  padding-right: 4px !important;
}
.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.p-2 {
  padding: 8px !important;
}
.pt-2 {
  padding-top: 8px !important;
}
.pr-2 {
  padding-right: 8px !important;
}
.pb-2 {
  padding-bottom: 8px !important;
}
.pl-2,
.px-2 {
  padding-left: 8px !important;
}
.px-2 {
  padding-right: 8px !important;
}
.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.p-3 {
  padding: 12px !important;
}
.pt-3 {
  padding-top: 12px !important;
}
.pr-3 {
  padding-right: 12px !important;
}
.pb-3 {
  padding-bottom: 12px !important;
}
.pl-3,
.px-3 {
  padding-left: 12px !important;
}
.px-3 {
  padding-right: 12px !important;
}
.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.p-4 {
  padding: 16px !important;
}
.pt-4 {
  padding-top: 16px !important;
}
.pr-4 {
  padding-right: 16px !important;
}
.pb-4 {
  padding-bottom: 16px !important;
}
.pl-4,
.px-4 {
  padding-left: 16px !important;
}
.px-4 {
  padding-right: 16px !important;
}
.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.p-5 {
  padding: 20px !important;
}
.pt-5 {
  padding-top: 20px !important;
}
.pr-5 {
  padding-right: 20px !important;
}
.pb-5 {
  padding-bottom: 20px !important;
}
.pl-5,
.px-5 {
  padding-left: 20px !important;
}
.px-5 {
  padding-right: 20px !important;
}
.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.p-6 {
  padding: 24px !important;
}
.pt-6 {
  padding-top: 24px !important;
}
.pr-6 {
  padding-right: 24px !important;
}
.pb-6 {
  padding-bottom: 24px !important;
}
.pl-6,
.px-6 {
  padding-left: 24px !important;
}
.px-6 {
  padding-right: 24px !important;
}
.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.p-7 {
  padding: 28px !important;
}
.pt-7 {
  padding-top: 28px !important;
}
.pr-7 {
  padding-right: 28px !important;
}
.pb-7 {
  padding-bottom: 28px !important;
}
.pl-7,
.px-7 {
  padding-left: 28px !important;
}
.px-7 {
  padding-right: 28px !important;
}
.py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.p-8 {
  padding: 32px !important;
}
.pt-8 {
  padding-top: 32px !important;
}
.pr-8 {
  padding-right: 32px !important;
}
.pb-8 {
  padding-bottom: 32px !important;
}
.pl-8,
.px-8 {
  padding-left: 32px !important;
}
.px-8 {
  padding-right: 32px !important;
}
.py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.p-9 {
  padding: 48px !important;
}
.pt-9 {
  padding-top: 48px !important;
}
.pr-9 {
  padding-right: 48px !important;
}
.pb-9 {
  padding-bottom: 48px !important;
}
.pl-9,
.px-9 {
  padding-left: 48px !important;
}
.px-9 {
  padding-right: 48px !important;
}
.py-9 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.p-10 {
  padding: 80px !important;
}
.pt-10 {
  padding-top: 80px !important;
}
.pr-10 {
  padding-right: 80px !important;
}
.pb-10 {
  padding-bottom: 80px !important;
}
.pl-10,
.px-10 {
  padding-left: 80px !important;
}
.px-10 {
  padding-right: 80px !important;
}
.py-10 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.p-11 {
  padding: 128px !important;
}
.pt-11 {
  padding-top: 128px !important;
}
.pr-11 {
  padding-right: 128px !important;
}
.pb-11 {
  padding-bottom: 128px !important;
}
.pl-11,
.px-11 {
  padding-left: 128px !important;
}
.px-11 {
  padding-right: 128px !important;
}
.py-11 {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}
.p-12 {
  padding: 208px !important;
}
.pt-12 {
  padding-top: 208px !important;
}
.pr-12 {
  padding-right: 208px !important;
}
.pb-12 {
  padding-bottom: 208px !important;
}
.pl-12,
.px-12 {
  padding-left: 208px !important;
}
.px-12 {
  padding-right: 208px !important;
}
.py-12 {
  padding-top: 208px !important;
  padding-bottom: 208px !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.mx-0 {
  margin-right: 0 !important;
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.m-1 {
  margin: 4px !important;
}
.mt-1 {
  margin-top: 4px !important;
}
.mr-1 {
  margin-right: 4px !important;
}
.mb-1 {
  margin-bottom: 4px !important;
}
.ml-1,
.mx-1 {
  margin-left: 4px !important;
}
.mx-1 {
  margin-right: 4px !important;
}
.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.m-2 {
  margin: 8px !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.mb-2 {
  margin-bottom: 8px !important;
}
.ml-2,
.mx-2 {
  margin-left: 8px !important;
}
.mx-2 {
  margin-right: 8px !important;
}
.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.m-3 {
  margin: 12px !important;
}
.mt-3 {
  margin-top: 12px !important;
}
.mr-3 {
  margin-right: 12px !important;
}
.mb-3 {
  margin-bottom: 12px !important;
}
.ml-3,
.mx-3 {
  margin-left: 12px !important;
}
.mx-3 {
  margin-right: 12px !important;
}
.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.m-4 {
  margin: 16px !important;
}
.mt-4 {
  margin-top: 16px !important;
}
.mr-4 {
  margin-right: 16px !important;
}
.mb-4 {
  margin-bottom: 16px !important;
}
.ml-4,
.mx-4 {
  margin-left: 16px !important;
}
.mx-4 {
  margin-right: 16px !important;
}
.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.m-5 {
  margin: 20px !important;
}
.mt-5 {
  margin-top: 20px !important;
}
.mr-5 {
  margin-right: 20px !important;
}
.mb-5 {
  margin-bottom: 20px !important;
}
.ml-5,
.mx-5 {
  margin-left: 20px !important;
}
.mx-5 {
  margin-right: 20px !important;
}
.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.m-6 {
  margin: 24px !important;
}
.mt-6 {
  margin-top: 24px !important;
}
.mr-6 {
  margin-right: 24px !important;
}
.mb-6 {
  margin-bottom: 24px !important;
}
.ml-6,
.mx-6 {
  margin-left: 24px !important;
}
.mx-6 {
  margin-right: 24px !important;
}
.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.m-7 {
  margin: 28px !important;
}
.mt-7 {
  margin-top: 28px !important;
}
.mr-7 {
  margin-right: 28px !important;
}
.mb-7 {
  margin-bottom: 28px !important;
}
.ml-7,
.mx-7 {
  margin-left: 28px !important;
}
.mx-7 {
  margin-right: 28px !important;
}
.my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
.m-8 {
  margin: 32px !important;
}
.mt-8 {
  margin-top: 32px !important;
}
.mr-8 {
  margin-right: 32px !important;
}
.mb-8 {
  margin-bottom: 32px !important;
}
.ml-8,
.mx-8 {
  margin-left: 32px !important;
}
.mx-8 {
  margin-right: 32px !important;
}
.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.m-9 {
  margin: 48px !important;
}
.mt-9 {
  margin-top: 48px !important;
}
.mr-9 {
  margin-right: 48px !important;
}
.mb-9 {
  margin-bottom: 48px !important;
}
.ml-9,
.mx-9 {
  margin-left: 48px !important;
}
.mx-9 {
  margin-right: 48px !important;
}
.my-9 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.m-10 {
  margin: 80px !important;
}
.mt-10 {
  margin-top: 80px !important;
}
.mr-10 {
  margin-right: 80px !important;
}
.mb-10 {
  margin-bottom: 80px !important;
}
.ml-10,
.mx-10 {
  margin-left: 80px !important;
}
.mx-10 {
  margin-right: 80px !important;
}
.my-10 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.m-11 {
  margin: 128px !important;
}
.mt-11 {
  margin-top: 128px !important;
}
.mr-11 {
  margin-right: 128px !important;
}
.mb-11 {
  margin-bottom: 128px !important;
}
.ml-11,
.mx-11 {
  margin-left: 128px !important;
}
.mx-11 {
  margin-right: 128px !important;
}
.my-11 {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}
.m-12 {
  margin: 208px !important;
}
.mt-12 {
  margin-top: 208px !important;
}
.mr-12 {
  margin-right: 208px !important;
}
.mb-12 {
  margin-bottom: 208px !important;
}
.ml-12,
.mx-12 {
  margin-left: 208px !important;
}
.mx-12 {
  margin-right: 208px !important;
}
.my-12 {
  margin-top: 208px !important;
  margin-bottom: 208px !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
